import { Component, OnInit } from '@angular/core';
import { BrandService } from './brand.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  brand: string;
  title = 'app';

  constructor(private brandService: BrandService) { }

  ngOnInit(): void {
    this.brandService.getBrand()
      .then((brand: string) => this.brand = brand)
      .catch((error: any) => console.error(error));

  }

}
