import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { error } from 'protractor';
import { Product } from '../models/productModel';
import { Category } from '../models/categoryModel';
import { ActivatedRoute, Router } from '@angular/router';
import { PagedProducts } from '../models/pagedProductsModel';
import { PointsService } from '../points/points.service';
import { CartService } from '../cart/cart.service';
import { Subscription } from 'rxjs';
import { BrandService } from '../brand.service';

@Component({
  selector: 'app-eShop-list-products',
  templateUrl: './eShop-list-products.component.html'
})
export class eShopListProductsComponent implements OnInit{

  public isLoading = true;
  public products: Product[] = [];

  pager = {};
  pageOfItems = [];
  page: number = 1;
  totalItems: number;
  categoryId: number;
  searchString: string;
  categoryName: string;
  subscription: Subscription;
  messages: any[] = [];
  p: number;
  brand: string;


  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private route: ActivatedRoute,
    private activeRouter: ActivatedRoute,
    private router: Router, private pointsService: PointsService,
    private cartService: CartService,
    private brandService: BrandService  ) {

    this.subscription = this.cartService.getMessage().subscribe(message => {
      if (message.text == "RefreshCart") {
        this.updateCanBeAddedToCartProductProperty();
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });

  }

  ngOnInit() {
    this.activeRouter.queryParams.subscribe(routeParams => {    
      this.loadProducts();
      this.brandService.getBrand()
        .then((brand: string) => { this.brand = brand; console.log(this.brand); })
        .catch((error: any) => console.error(error));
    });
  
  }

  changePage(page)
  {
  
    this.router.navigate([], {
      queryParams: {
        pageNumber: page
      },
      queryParamsHandling: 'merge',
    }).then(result => {
     // console.log(page);
      this.p = page;
      //this.loadProducts();
    });
  }

  loadProducts() {
    console.log("loadProducts");
      this.p = this.activeRouter.snapshot.queryParams['pageNumber'];
      this.products = [];
      this.isLoading = true;
      this.categoryId = this.activeRouter.snapshot.queryParams['categoryId'];
      this.categoryName = this.activeRouter.snapshot.queryParams['categoryName'];
      this.searchString = this.activeRouter.snapshot.queryParams['searchString'];
      this.page = this.activeRouter.snapshot.queryParams['pageNumber'];
      let url = this.baseUrl + 'products' + '?pageNumber=' + this.page + '&loadApiDetails=true';

      if (this.categoryId)
        url = url + '&categoryId=' + this.categoryId;

      if (this.searchString)
        url = url + '&searchString=' + this.searchString;

      this.http.get<PagedProducts>(url).subscribe(result => {
        this.products = result.products;
        this.totalItems = result.totalItems;
        this.isLoading = false;

        this.updateCanBeAddedToCartProductProperty();

      }, error => console.error(error));
    

  }


  updateCanBeAddedToCartProductProperty() {
    this.cartService.getCartPoints().then(
      result => {
        console.log(this.cartService.cartPoints);
        this.products.forEach(p => {
          if (p.points <= (this.pointsService.availablePoints - this.cartService.cartPoints))
            p.canBeAddedToCart = true;
          else
            p.canBeAddedToCart = false;

        })
      });
  }
}


