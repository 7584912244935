import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  private brand: string;

  constructor(private http: HttpClient) { }

  getBrand(): Promise<string> {
    if (this.brand) {
      return Promise.resolve(this.brand);
    } else {
      return this.http.get<string>('/salesforce/getbrand')
        .toPromise()
        .then((brand: string) => {
          this.brand = brand;
          return this.brand;
        })
        .catch((error: any) => {
          console.error(error);
          throw error;
        });
    } 
  }
}
