import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { error } from 'protractor';
import { PagedProducts } from '../models/pagedProductsModel';
import { Order } from '../models/orderModel';
import { concat } from 'rxjs/operators';

@Component({
  selector: 'app-list-orders',
  templateUrl: './list-orders.component.html'
})
export class ListOrdersComponent {
  public orders: Order[];
  public isLoading: boolean = true;
  public totalItems: number;
  isAdmin: boolean = false;

  p: number = 1;
  
  searchString: string = '';

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {

    this.http.get<any>(this.baseUrl + 'salesForce/clientInfo').subscribe(result => {
      if (result.isAdmin) {
        this.isAdmin = true;       
      }
      this.loadOrders();
    }, error => console.error(error));


  }

  loadOrders() {
    
    this.orders = [];
    this.isLoading = true;
    let url = this.baseUrl + 'salesForce/orders';

    if (this.searchString.trim() !== '')
      url = url + '?orderId=' + this.searchString;

    this.http.get<Order[]>(url).subscribe(
      result => {
        this.orders = result;
        this.isLoading = false;
      }, error => { this.isLoading = false; console.error(error) });
  }
  
  cancelOrder(id) {
    this.http.post(this.baseUrl + 'salesForce/cancelOrder?orderId=' + id,null).
      subscribe(result => {
        console.log(result);
        if (result == true) {
          this.loadOrders();
        }
      },
        error => console.error(error));    
  }


}

