import { Component, OnInit, Input, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientInfo } from '../models/clientModel';
import { CartService } from '../cart/cart.service';

import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
 // styleUrls: ['./hero-detail.component.css']
})
export class Checkout implements OnInit {
  client: ClientInfo = { clientAddress:'', clientName: '', clientPhone:'', contactId:'' };
  cartPoints: number;
  subscription: Subscription;
  messages: any[] = [];
  errorOrderMessage: string;
  _router: any;
  public isLoading: boolean = false;
  isCartInStock: boolean = false;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private cartService: CartService, private router:Router) {

    this.subscription = this.cartService.getMessage().subscribe(message => {
      if (message.text == "RefreshCart") {
        this.cartService.getCartPoints().then(result => { this.cartPoints = this.cartService.cartPoints; this.isCartInStock = this.cartService.isCartInStock; });
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });

    this.http.get<any>(this.baseUrl + 'salesforce/clientInfo').subscribe(result => {
      this.client = result;
    }, error => console.error(error));
  }

  ngOnInit() {
    this.cartService.getCartPoints().then(result => { this.cartPoints = this.cartService.cartPoints; this.isCartInStock = this.cartService.isCartInStock; });
  }

  sendOrder() {

    this.isLoading = true;

    this.http.post<any>(this.baseUrl + 'salesforce',null).subscribe(result => {
      if (!result.isSucces) {
        this.isLoading = false;
        this.errorOrderMessage = result.reason;
        //if (result.reason == "Puncte insuficiente") {
        //  this.errorOrderMessage = "Puncte insuficiente. Valoarea cosului a fost actualizata!";
        //  this.cartService.sendMessage("RefreshCart");
        //  this.isLoading = false;
        //}
        //if (result.reason == "Eroare aplicatie calcul puncte") {
        //  this.errorOrderMessage = "Eroare aplicatie calcul puncte";
        //  this.isLoading = false;
        //  if (result.reason == "Eroare salvare comanda") {
        //    this.isLoading = false;
        //    this.errorOrderMessage = "Eroare salvare comanda";
        //  }
        //}
      }
      else {
        this.http.delete(this.baseUrl + 'cart').subscribe(result => {
          this.cartService.sendMessage("RefreshCart");
          this.isLoading = false;
          this.router.navigate(['/thank-you']);
        }, error => console.error(error));
      }
     
    }, error => console.error(error));
  }

}
