import { Product } from '../models/productModel';
import { Component, OnInit, Input, Inject, EventEmitter, Output, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Cart } from '../cart/cart.component';
import { CartService } from '../cart/cart.service';
import { PointsService } from '../points/points.service';

@Component({
  selector: 'app-list-product',
  templateUrl: './eShop-list-product.component.html',
  // styleUrls: ['./hero-detail.component.css']
})
export class eShopListProductComponent implements OnInit {
  @Input() product: Product;
  productWasAdded: boolean = false;
  productWasNotAdded: boolean = false;
  loadingAdd: boolean = false;
  availablePoints: number = 0;
  cartPoints: number;


  insufficientFounds: boolean = false;
  insufficientPointsOnAdd: boolean = false;

  constructor(private cartService: CartService, private pointsService:PointsService) { }

  ngOnInit() {

  }

  showError() {
    this.productWasAdded = false;
    this.productWasNotAdded = true;
    this.loadingAdd = false;
  }

  addToCart(productId) {
    this.loadingAdd = true;

    this.cartService.getCartPoints().then(result => {
      this.cartPoints = this.cartService.cartPoints;
      if (this.product.points >  (this.pointsService.availablePoints - this.cartPoints)) {
        this.showError();
      }
      else {
        var cartItem = { productId: productId, quantity: 1 };
        this.cartService.addToCart(cartItem).subscribe(result => {
          this.cartService.sendMessage("RefreshCart");
          this.productWasAdded = true;
          this.loadingAdd = false;
        });
      }
    }, error => {
      this.showError();
    });
  }

}
