import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { error } from 'protractor';
import { Category } from '../models/categoryModel';

@Component({
  selector: 'app-list-categories',
  templateUrl: './list-categories.component.html'
})
export class ListCategoriesComponent {
  public categories: Category[] = [];
  public isLoading: boolean = true;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.isLoading = true;
    http.get<Category[]>(baseUrl + 'categories').subscribe(result => {
      this.categories = result;
      this.isLoading = false;
    }, error => console.error(error));
  }

  
  deleteCategory(id, index) {
    this.http.delete(this.baseUrl + 'categories/' + id).
      subscribe(result => { this.categories.splice(index, 1); },
        error => console.error(error));    
  }
}

