import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { CartService } from '../cart/cart.service';
import { Cart } from '../cart/cart.component';
import { PointsService } from './points.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html'
})

export class Points implements OnInit,OnDestroy {

 subscription: Subscription;
  messages: any[] = [];
  availablePoints: number = 0;
  cartPoints: number = 0;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private cartService: CartService, private pointsService: PointsService) {
    this.subscription = this.cartService.getMessage().subscribe(message => {
      if (message.text == "RefreshCart") {
        this.cartService.getCartPoints().then(result => { this.cartPoints = this.cartService.cartPoints });
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
        
    
  }

   ngOnInit() {
     this.cartService.getCartPoints().then(result => { this.cartPoints = this.cartService.cartPoints });

     setTimeout(() => {
       if (this.pointsService.availablePoints) {
         this.availablePoints = this.pointsService.availablePoints;
       }
       else {
         this.pointsService.getAvailablePoints().then(result => this.availablePoints = result);
       }
     },
       1000);
    
   
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

}
