import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CartService } from './cart.service';
import { CartItem } from "../models/cartModel";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html'
})

export class Cart implements OnInit {

  cart: CartItem[] = [];
  messages: any[] = [];
  cartPoints: number = 0;
  subscription: Subscription;

  constructor(private http: HttpClient, private cartService: CartService, private route: ActivatedRoute) {
    this.subscription = this.cartService.getMessage().subscribe(message => {
      if (message.text == "RefreshCart") {
        this.loadCart();
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
  }

  ngOnInit() {
    this.loadCart();
  }

  loadCart() {
    this.cartService.getCart().subscribe(cart => { this.cart = cart; this.cartService.getCartPoints().then(result => this.cartPoints = this.cartService.cartPoints); });    
  }

  removeFromCart(id) {
    this.cartService.removeFromCart(id).subscribe(cart => this.cartService.sendMessage("RefreshCart"));   
  }

}
