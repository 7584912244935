import { Injectable, Inject } from "@angular/core";
import { Observable, Subject } from 'rxjs';
import { CartItem } from "../models/cartModel";
import { HttpClient } from "@angular/common/http";


@Injectable({ providedIn: 'root' })
export class CartService {
  private subject = new Subject<any>();
  public cart = new Observable<CartItem[]>();
  public cartPoints: number = 0;
  public isCartInStock: boolean = false;


  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.refreshCart();
  }
   
  refreshCart() {
    this.cart = this.http.get<CartItem[]>(this.baseUrl + 'cart');    
  }

  getCart(): Observable<CartItem[]> {
    return this.cart;
  }

  removeFromCart(id):Observable<any[]> {
    return this.http.delete<any[]>(this.baseUrl + 'cart/' + id);   

  }

  addToCart(cartItem): Observable<any> {
    return this.http.post(this.baseUrl + 'cart/', cartItem);

  }

  getCartPoints() {

    return this.cart.forEach(cart => {
      this.cartPoints = 0; this.isCartInStock = true;
      cart.forEach(cartItem => {
        this.cartPoints += cartItem.product.points * cartItem.quantity
        if (!cartItem.product.inStock) this.isCartInStock = false;
      })
    });
   } 

   sendMessage(message: string) {
    this.subject.next({ text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

}
