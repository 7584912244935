import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { error } from 'protractor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Product } from '../models/productModel';
import { CartService } from '../cart/cart.service';
import { PointsService } from '../points/points.service';
import { Subscription } from 'rxjs';

declare function swiperInitializare(): void;


@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit{

  productId: number;
  product: Product;
  widgetsContent: any;
  productWasAdded: boolean = false;
  productWasNotAdded: boolean = false;
  loadingAdd: boolean = false;
  cartPoints: number = 0;
  subscription: Subscription;
  messages: any[] = [];

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private formBuilder: FormBuilder,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private cartService: CartService,
    private pointsService: PointsService
  ) {
    this.subscription = this.cartService.getMessage().subscribe(message => {
      if (message.text == "RefreshCart") {
        this.setCanBeAddedToCart();    
    } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
  }



  setCanBeAddedToCart() {
    this.cartService.getCartPoints().then(
      result => {
        //console.log("Product points:" + this.product.points);
        //console.log("Available points:" + this.pointsService.availablePoints);
        //console.log("cartPoints points:" + this.cartService.cartPoints);
        if (this.product.points > (this.pointsService.availablePoints - this.cartService.cartPoints)) {
          this.product.canBeAddedToCart = false;
        }
        else {
          this.product.canBeAddedToCart = true;
        }      
      });    
  }

  ngOnInit() {
    const productId = this.activeRouter.snapshot.params['id'];

    this.http.get<Product>(this.baseUrl + 'products/details/' + productId)
      .subscribe(result => {
        this.product = result;
        this.productId = this.product.id;
        this.setCanBeAddedToCart();
        setTimeout(() => {
          swiperInitializare();
        }, 10);
      });



  }
  
  showError() {
    this.productWasAdded = false;
    this.productWasNotAdded = true;
    this.loadingAdd = false;
  }

  addToCart(productId) {
    this.loadingAdd = true;

    this.cartService.getCartPoints().then(result => {
      if (this.product.points > (this.pointsService.availablePoints - this.cartService.cartPoints)) {
        this.showError();
      }
      else {
        var cartItem = { productId: productId, quantity: 1 };
        this.cartService.addToCart(cartItem).subscribe(result => {
          this.cartService.sendMessage("RefreshCart");
          this.productWasAdded = true;
          this.loadingAdd = false;
        });
      }
    }, error => {
      this.showError();
    });
  }
  
}

