import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { ListCategoriesComponent } from './categories/list-categories.component';
import { AddCategoryComponent } from './categories/add-category.component';
import { EditCategoryComponent } from './categories/edit-category.component';
import { ListProductsComponent } from './products/list-products.component';
import { AddProductComponent } from './products/add-product.component';
import { EditProductComponent } from './products/edit-product.component';
import { ViewProductComponent } from './products/view-product.component';
import { ParentCategoriesPipe, ChildCategoriesPipe } from './categories/filter-category-pipes';
import { RequiredIfDirective } from './required-if.directive';
import { NgxPaginationModule } from 'ngx-pagination'; 
import { HomePageComponent } from './eShop/home-page.component';
import { eShopListProductsComponent } from './eShop/eShop-list-products.component';
import { eShopListProductComponent } from './eShop/eShop-list-product.component';
import { Checkout } from './checkout/checkout.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { Cart } from './cart/cart.component';
import { Points } from './points/points.component';
import { ListOrdersComponent } from './orders/list-orders.component';
import { ThankYouMessageComponent } from './thank-you-message/thank-you-message.component';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    ListCategoriesComponent,
    AddCategoryComponent,
    EditCategoryComponent,
    ListProductsComponent,
    AddProductComponent,
    EditProductComponent,
    ViewProductComponent,
    HomePageComponent,
    eShopListProductsComponent,
    eShopListProductComponent,
    Checkout,
    Cart,
    Points,
    ListOrdersComponent,
    ParentCategoriesPipe,
    ChildCategoriesPipe,
    RequiredIfDirective,
    ThankYouMessageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    //ApiAuthorizationModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    EditorModule,
    RouterModule.forRoot([
      { path: '', component: HomePageComponent, pathMatch: 'full' },
      //{ path: 'fetch-data', component: FetchDataComponent, canActivate: [AuthorizeGuard] },
      { path: 'list-categories', component: ListCategoriesComponent },
      { path: 'checkout', component: Checkout },
      { path: 'add-category', component: AddCategoryComponent },
      { path: 'edit-category/:id', component: EditCategoryComponent },
      { path: 'list-products', component: ListProductsComponent },
      { path: 'add-product', component: AddProductComponent },
      { path: 'edit-product/:id', component: EditProductComponent },
      { path: 'view-product/:id', component: ViewProductComponent },
      { path: 'home-page', component: HomePageComponent },
      //{ path: 'eShop-list-products/:categoryId/:categoryName/:searchString', component: eShopListProductsComponent },
      { path: 'eShop-list-products', component: eShopListProductsComponent },
      { path: 'list-orders', component: ListOrdersComponent },
      { path: 'thank-you', component: ThankYouMessageComponent },

      
    ])
  ],
  providers: [
   // { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // Initialize Swiper 
  }
}

