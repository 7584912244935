import { Pipe, PipeTransform } from "@angular/core";
import { Category } from "../models/categoryModel";

@Pipe({ name: 'parentCategories' })
export class ParentCategoriesPipe implements PipeTransform {
  transform(allCategories: Category[]) {
    return allCategories.filter(c => !c.parentCategoryId);
  }
}

@Pipe({ name: 'childCategories' })
export class ChildCategoriesPipe implements PipeTransform {
  transform(allCategories: Category[], parentCategoryId) {
    return allCategories.filter(c => c.parentCategoryId == parentCategoryId);
  }
}
