import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { error } from 'protractor';
import { Product } from '../models/productModel';
import { PagedProducts } from '../models/pagedProductsModel';
import { BrandService } from '../brand.service';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html'
})
export class ListProductsComponent {
  public products: Product[];
  public isLoading: boolean = true;
  public totalItems: number;

  p: number = 1;
  
  searchString: string = '';
  brand: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private brandService: BrandService) {

    this.brandService.getBrand()
      .then((brand: string) => this.brand = brand)
      .catch((error: any) => console.error(error));


    this.loadProducts();

   
    
  }

  loadProducts() {
    this.isLoading = true;
    let url = this.baseUrl + 'products'+'?pageNumber=' + this.p;;

    if (this.searchString) 
      url = url + '&searchString=' + this.searchString; 


    
    this.http.get<PagedProducts>(url).subscribe(
      result => {
      this.products = result.products;

      this.totalItems = result.totalItems;
        this.isLoading = false;
        console.log(this.products);

    },error => console.error(error));

  }

  
  deleteProduct(id, index) {
    this.http.delete(this.baseUrl + 'products/' + id).
      //subscribe(result => { this.products.splice(index, 1); },
      subscribe(result => { this.p = 1; this.loadProducts(); },
        error => console.error(error));    
  }

  checkStock() {
    this.isLoading = true;
    let url = this.baseUrl + 'products/checkStock' ;

    this.http.get(url).subscribe(
      result => {
        this.loadProducts();
      }, error => console.error(error));

  }


}

