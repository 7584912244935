import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { error } from 'protractor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Category } from '../models/categoryModel';
import { Supplyer } from '../models/supplyerModel';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html'
})
export class AddProductComponent implements OnInit{
  addForm: FormGroup;
  categories: Category[]
  supplyers: Supplyer[] = []
  supplyerHasApi: boolean = true;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private formBuilder: FormBuilder, private router: Router) {

    this.http.get<any[]>(this.baseUrl + 'categories').subscribe(result => {
      this.categories = result;
    }, error => console.error(error));

    this.http.get<any[]>(this.baseUrl + 'supplyiers').subscribe(result => {
      this.supplyers = result;
    }, error => console.error(error));

  }

  ngOnInit() {

    this.addForm = this.formBuilder.group({
      identifier: ['', Validators.compose([Validators.required])],
      category: ['', Validators.compose([Validators.required])],
      supplyer: ['1', Validators.compose([Validators.required])],
      name: [''],
      description: [''],
      price: [''],
      imageLink: [''],
    });
   
  }

  addProduct() {
    const product = {
      identifier: this.addForm.controls.identifier.value, 
      categoryId: parseInt(this.addForm.controls.category.value),
      supplyierId: parseInt(this.addForm.controls.supplyer.value),
      name: this.addForm.controls.name.value,
      description: this.addForm.controls.description.value,
      price: parseFloat(this.addForm.controls.price.value),
      imageLink: this.addForm.controls.imageLink.value,
    };
    this.http.post(this.baseUrl + 'products/', product).subscribe(result => { this.router.navigate(['/list-products']); }, error => console.error(error));    
  }

  onOptionsSelected(selectedValue)
  {
    if (selectedValue)
      this.supplyerHasApi = this.supplyers.filter(x => x.id == selectedValue)[0].hasApi;
  }
}

