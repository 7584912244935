import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { error } from 'protractor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { resolveAny } from 'dns';
import { Observable } from 'rxjs';
import { Category } from '../models/categoryModel';
import { Supplyer } from '../models/supplyerModel';
import { Product } from '../models/productModel';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html'
})
export class EditProductComponent implements OnInit{
  editForm: FormGroup;
  productId: number;
  categories: Category[]
  supplyers: Supplyer[]
  supplyerHasApi: boolean = false;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private formBuilder: FormBuilder, private router: Router, private activeRouter: ActivatedRoute) {
    http.get<any[]>(baseUrl + 'categories').subscribe(result => {
      this.categories = result;
    }, error => console.error(error));

    http.get<any[]>(baseUrl + 'supplyiers').subscribe(result => {
      this.supplyers = result;
    }, error => console.error(error));
  }

  ngOnInit() {
    const productId = this.activeRouter.snapshot.params['id'];

    this.http.get(this.baseUrl + 'products/' + productId)
      .subscribe(data => {
        let product = data as Product;
        this.productId = product.id;
        this.supplyerHasApi = product.supplyier.hasApi;
        this.editForm.setValue({
          productIdentifier: product.identifier,
          productName: product.name,
          category: product.categoryId,
          supplyer: product.supplyierId,
          description: product.description,
          price: product.price,
          imageLink: product.imageLink
        });       
      });

    this.editForm = this.formBuilder.group({
      productIdentifier: ['', Validators.compose([Validators.required])],
      productName: ['', Validators.compose([Validators.required])],
      category: ['', Validators.compose([Validators.required])],
      supplyer: ['', Validators.compose([Validators.required])],
      description: [''],
      price: [''],
      imageLink: [''],
    });
  }
  
  updateForm() {
    const product = {
      id: this.productId,
      identifier: this.editForm.controls.productIdentifier.value,
      name: this.editForm.controls.productName.value,
      categoryId: parseInt(this.editForm.controls.category.value),
      supplyierId: parseInt(this.editForm.controls.supplyer.value),
      description: this.editForm.controls.description.value,
      price: parseFloat(this.editForm.controls.price.value),
      imageLink: this.editForm.controls.imageLink.value,
    };
    this.http.put(this.baseUrl + 'products/' + this.productId, product).subscribe(result => { this.router.navigate(['/list-products']); }, error => console.error(error));    
  }
}
