import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from '../models/categoryModel';
import { Location } from '@angular/common';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {

  isExpanded = false;
  menuToggle: boolean = false;
  userToggle: boolean = false;
  categories: Category[] = [];
  public selectedCategoryId: number;
  cartToggle: boolean = false;
  categToggle: boolean = false;
  isAdmin: boolean = false;
  showLogin: boolean = false;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private route: ActivatedRoute, private _location: Location, private router: Router) {
    http.get<any[]>(baseUrl + 'categories').subscribe(result => {
      this.categories = result;
    }, error => console.error(error));

    this.http.get<any>(this.baseUrl + 'salesforce/clientinfo').subscribe(result => {
      if (result.clientName == "Neautentificat") {
        this.showLogin = true;
       // window.location.href = this.baseUrl + "customer/account/login";
      }
      else {
        this.showLogin = false;
      }
      if (result.isAdmin) {
        this.isAdmin = true;
      }
    }, error => console.error(error));
  }
  backClicked() {
    this._location.back();
  }
  toggleClass() {
    this.menuToggle = !this.menuToggle;
    this.cartToggle = false;
    this.userToggle = false;
  }

  userToggleClass() {
    this.userToggle = !this.userToggle;
    this.cartToggle = false;
    this.menuToggle = false;

  }

  toggleCart() {
    this.menuToggle = false;
    this.userToggle = false;
    this.cartToggle = !this.cartToggle;    
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  toggleCategClass(category) {
    category.isChecked = !category.isChecked;
    //this.categToggle = !this.categToggle;
  }

  login()
  {
    window.location.href = this.baseUrl + "customer/account/login";
  }


}
