import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { error } from 'protractor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Category } from '../models/categoryModel';


@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html'
})
export class AddCategoryComponent implements OnInit{
  addForm: FormGroup;
  categories: Category[] = [];

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private formBuilder: FormBuilder, private router: Router) {
    http.get<any[]>(baseUrl + 'categories').subscribe(result => {
      this.categories = result;
    }, error => console.error(error));
  }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      title: ['', Validators.compose([Validators.required])],
      parentCategory: [''],
    });
  }
  
  addCategory() {
    const category = {
      name: this.addForm.controls.title.value,
      parentCategoryId: parseInt(this.addForm.controls.parentCategory.value)
    };
    this.http.post(this.baseUrl + 'categories/', category).subscribe(result => { this.router.navigate(['/list-categories']); }, error => console.error(error));    
  }

}

