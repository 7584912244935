import { Injectable, Inject } from "@angular/core";
import { Observable, Subject } from 'rxjs';
import { CartItem } from "../models/cartModel";
import { HttpClient } from "@angular/common/http";


@Injectable({ providedIn: 'root' })
export class PointsService {
  availablePoints: number = 0 ;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.http.get<number>(this.baseUrl + 'salesforce').subscribe(result => this.availablePoints = result);
  }

  getAvailablePoints(): Promise<number> {
    return this.http.get<number>(this.baseUrl + 'salesforce').toPromise();
  }
   
}
