import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { error } from 'protractor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { resolveAny } from 'dns';
import { Observable } from 'rxjs';
import { Category } from '../models/categoryModel';


@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html'
})
export class EditCategoryComponent implements OnInit{
  editForm: FormGroup;
  categoryId: number;
  parentCategoryId: number;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private formBuilder: FormBuilder, private router: Router, private activeRouter: ActivatedRoute) {
  }

  ngOnInit() {
    const categoryId = this.activeRouter.snapshot.params['id'];

    this.http.get(this.baseUrl + 'categories/' + categoryId)
      .subscribe(data => {
        let category = data as Category;
        this.categoryId = category.id;
        this.parentCategoryId = category.parentCategoryId;
        this.editForm.setValue({
          categoryName: category.name
        });
      });

    this.editForm = this.formBuilder.group({
      categoryName: ['', Validators.compose([Validators.required])],
    });
  }
  
  updateForm() {
    const category = {
      id: this.categoryId,
      parentCategoryId: this.parentCategoryId,
      name: this.editForm.controls.categoryName.value
    };
    this.http.put(this.baseUrl + 'categories/' + this.categoryId, category).subscribe(result => { this.router.navigate(['/list-categories']); }, error => console.error(error));    
  }
}
