import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { error } from 'protractor';
import { Product } from '../models/productModel';
import { Category } from '../models/categoryModel';
import { ActivatedRoute, Router } from '@angular/router';
import { PagedProducts } from '../models/pagedProductsModel';
import { CartService } from '../cart/cart.service';
import { PointsService } from '../points/points.service';
import { Subscription } from 'rxjs';
import { BrandService } from '../brand.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html'
})
export class HomePageComponent {

  public isLoading = false;
  public latestProducts: Product[] = [];
  searchString: string;
  subscription: Subscription;
  messages: any[] = [];
  brand: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private route: ActivatedRoute, private cartService: CartService, private pointsService: PointsService,
    private router: Router, private brandService: BrandService) {

    this.brandService.getBrand()
      .then((brand: string) => this.brand = brand)
      .catch((error: any) => console.error(error));

    this.subscription = this.cartService.getMessage().subscribe(message => {
      if (message.text == "RefreshCart") {
        this.updateCanBeAddedToCartProductProperty();
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });

    http.get<Product[]>(baseUrl + 'products/latestProducts').subscribe(result => {
      this.latestProducts = result;
      this.updateCanBeAddedToCartProductProperty();
    }, error => console.error(error));
  }


  updateCanBeAddedToCartProductProperty() {
    this.cartService.getCartPoints().then(
      result => {
        this.latestProducts.forEach(p => {
          if (p.points <= (this.pointsService.availablePoints - this.cartService.cartPoints))
            p.canBeAddedToCart = true;
          else
            p.canBeAddedToCart = false;

        })
      });
  }

  callSearchButton() {
    this.router.navigate(["/eShop-list-products"], {
      queryParams: {
        searchString: this.searchString,
        pageNumber: 1
      },
    }).then(result => {});
  }

}


